import React from 'react'
import Layout from '../components/Layout'
import { Spin, Result } from 'antd';

const AdminGranted = ({ location }) => (
  <>
    <div style={{ textAlign: 'center' }}>
    <Result
    status="success"></Result>
    <h1>Admin Consent Granted</h1>
    </div>
  </>
)

export default AdminGranted
