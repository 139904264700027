import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import { Helmet } from 'react-helmet'

const Layout = ({ children, location }) => (
  
      <>
        <Helmet
          title={"CalendarBridge"}
          meta={[
            {
              name: 'description',
              content:
                'Never Get Double Booked Again. Keep calendars in sync across different organizations.',
            },
            {
              name: 'keywords',
              content:
                'calendar, sync, double booked, booking, scheduling, schedule, appointment, consultant, secure, o365, gsuite, micrososft 365, google workplace',
            },
          ]}
        >
          <html lang="en" />
          {/* <style type="text/css">{`
            body {
              background-image: url(${background});
              background-size: 20%;
            }
        `}</style> */}
        </Helmet>
        {children}
      </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
}

export default Layout
